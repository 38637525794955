import Table from "../../components/Table";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useState, useEffect } from "react";
import api from "../../api";
import axios from "axios";
import FormModal from "../../components/FormModal";
import { useForm } from "../../useForm";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  inputs: {
    minWidth: "380px",
    maxWidth: "600px",
    margin: "1rem 0",
  },
}));

function UsersContent(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser, resetNewUser] = useForm({
    name: "",
    email: "",
    password: "",
    repeatPass: "",
    level: 1,
  });
  const [adding, setAdding] = useState(false);

  const newUserModal = (
    <div>
      <TextField
        type="text"
        name="name"
        placeholder="Nome"
        className={classes.inputs}
        value={newUser.name}
        onChange={setNewUser}
        inputProps={{ minLength: 6, maxLength: 24 }}
        required
      />
      <br />
      <TextField
        type="text"
        name="email"
        placeholder="E-mail"
        className={classes.inputs}
        value={newUser.email}
        onChange={setNewUser}
        required
      />
      <br />
      <TextField
        type="password"
        name="password"
        placeholder="Senha"
        className={classes.inputs}
        value={newUser.password}
        onChange={setNewUser}
        inputProps={{ minLength: 8, maxLength: 32 }}
        required
      />
      <br />
    </div>
  );

  const getUsers = () => {
    axios
      .get(api.url + "/users/company/session", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUser = () => {
    axios
      .post(api.url + "/users", newUser, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setAdding(false);
        resetNewUser();
        getUsers();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const deleteSelected = (ids) => {
    axios
      .delete(api.url + "/users/company/session", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
        data: {
          ids,
        },
      })
      .then(() => {
        getUsers();
      });
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disabblePadding: true,
      label: "Nome",
    },
    {
      id: "email",
      numeric: false,
      disabblePadding: true,
      label: "E-mail",
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <FormModal
        open={adding}
        title="Adicionar usuário"
        content={newUserModal}
        actionButtonText="Adicionar"
        handleClose={() => setAdding(false)}
        handleSubmit={addUser}
      />
      <Table
        title={
          <PersonAddIcon
            onClick={() => setAdding(true)}
            style={{ color: "whitesmoke" }}
          />
        }
        deleteSelected={deleteSelected}
        data={users}
        headCells={headCells}
      />
    </div>
  );
}

export default UsersContent;
