import { Map, GoogleApiWrapper } from "google-maps-react";
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ReactDOM from "react-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import api from "../api";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import CancelIcon from "@material-ui/icons/Cancel";
import Combos from "./Combos";
import ProjectsList from "./ProjectsList";
import MuiAlert from "@material-ui/lab/Alert";
import FieldsTable from "./FieldsTable";

const defaultFieldColors = {
  fillColor: "#17b978",
};

const selectedFieldColors = {
  fillColor: "red",
};

const legendBaseStyle = {
  backgroundColor: "whitesmoke",
  padding: "1rem",
  color: "#17b978",
  margin: "1rem",
  borderRadius: "4px",
  minWidth: "300px",
  maxWidth: "400px",
};

const rightLegendStyle = {
  backgroundColor: "whitesmoke",
  padding: "1rem",
  color: "#17b978",
  margin: "1rem",
  borderRadius: "4px",
  minWidth: "300px",
  maxWidth: "400px",
  maxHeight: "600px",
  overflowY: "auto",
};

function MapContainer(props) {
  //Map stuff.
  const [map, setMap] = useState(null);
  const [mapProps, setMapProps] = useState(null);

  //Overview/final stuff
  const [projectName, setProjectName] = useState(null);
  const [inputFile, setInputFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [areaInfo, setAreaInfo] = useState(null);
  const [price, setPrice] = useState(0);
  const [prices, setPrices] = useState(null);
  const [credits, setCredits] = useState(null);
  const [irpNewName, setIrpNewName] = useState(null);
  const [outputFolderName, setOutputFolderName] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [fields, setFields] = useState([]);

  //Projects list stuff
  const [projects, setProjects] = useState([]);

  //Error handling
  const [error, setError] = useState(null);

  const mapRef = React.createRef();
  const inputRef = React.createRef();
  const projectsRef = React.createRef();

  const getProjectsList = () => {
    axios({
      method: "get",
      url: api.url + "/irplus/projects",
      data: {},
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setProjects(response.data.projects || []);
      })
      .catch((err) => {
        setError(err.response.data.message || "Algo deu errado!");
      });
  };

  const runFinal = () => {
    axios({
      method: "post",
      url: api.url + "/irplus/final",
      data: {
        projectName,
        irpName: irpNewName,
        prices,
        outputFolderName,
        fields,
      },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        resetProject();
        getProjectsList();
      })
      .catch((err) => {
        setError(err.response.data.message || "Algo deu errado!");
      });
  };

  const runOverview = () => {
    return new Promise((resolve, reject) => {
      try {
        const form = new FormData();
        form.append("inputFile", inputFile);
        form.append("projectName", projectName);
        axios({
          method: "post",
          url: api.url + "/irplus/overview",
          data: form,
          headers: {
            "access-token": localStorage.getItem("token"),
          },
          onUploadProgress: (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total);
            setUploadProgress(percent);
          },
        })
          .then((response) => {
            const fields = Object.keys(response.data.geoJson.features);
            setFields(
              fields.map((field) => {
                return {
                  firstName:
                    response.data.geoJson.features[field].properties.name,
                  newName:
                    response.data.geoJson.features[field].properties.name,
                };
              })
            );
            setUploadProgress(0);
            setUploading(false);
            //FIXME: Refactor this shit, please. (maybe use class component ?)
            setCredits(response.data.credits);
            setAreaInfo(response.data.data);
            setPrices(response.data.prices);
            setIrpNewName(response.data.irpName);
            setOutputFolderName(response.data.outputFolderName);
            resolve({
              geoJsonSrc:
                api.url +
                `/irplus/gmaps/download/${response.data.outputFolderName}?company=${response.data.company}&token=${response.data.gmapsToken}`,
              latitude: response.data.data.latitude,
              longitude: response.data.data.longitude,
            });
          })
          .catch((err) => {
            setUploadProgress(0);
            setUploading(false);
            setError(err.response.data.message || "Algo deu errado!");
          });
      } catch (e) {
        reject(e);
      }
    });
  };

  const loadGeoJson = async (src, latitude, longitude) => {
    map.data.loadGeoJson(src);
    const overview_center = new props.google.maps.LatLng(latitude, longitude);
    map.setCenter(overview_center);
    map.setZoom(16);
  };

  const addFielsListener = () => {
    map.data.addListener("click", (event) => {
      setSelectedField(event.feature.j.name);
    });
  };

  const projectOverview = async (e) => {
    try {
      e.preventDefault();
      setUploading(true);
      const { geoJsonSrc, latitude, longitude } = await runOverview();
      loadGeoJson(geoJsonSrc, latitude, longitude);
      map.data.setStyle((feature) => {
        return defaultFieldColors;
      });
      addFielsListener();
    } catch (e) {
      setError("Algo deu errado!");
    }
  };

  const inputLegend = (
    <div>
      <Typography variant="h5">IRPlus</Typography>
      <Divider style={{ margin: "0.5rem 0rem" }} />
      <form onSubmit={projectOverview}>
        <TextField
          type="text"
          name="projectName"
          placeholder="Nome do projeto"
          value={projectName}
          onChange={(e) =>
            setProjectName(
              e.target.value.replace(/[^\w\s]/gi, "").replace(/ /g, "")
            )
          }
          style={{ width: "100%" }}
          autoComplete="off"
          inputProps={{ maxLength: 16 }}
          required
        />
        <br />
        <input
          type="file"
          name="inputFile"
          id="irpInput"
          onChange={(e) => {
            setInputFile(e.target.files[0]);
          }}
          style={{ display: "none" }}
          accept=".irp,.zip"
          required
        />
        <label
          htmlFor="irpInput"
          className="green-label"
          style={{ marginTop: "1rem" }}
        >
          {inputFile ? inputFile.name : "Selecionar IRP"}
        </label>
        <Divider style={{ margin: "0.5rem 0rem" }} />
        <Button
          variant="contained"
          color="primary"
          disabled={inputFile && projectName ? false : true}
          style={{ marginTop: "0.5rem" }}
          type="submit"
        >
          {uploadProgress === 100
            ? "Extraindo informações"
            : "Enviar novo projeto"}
        </Button>
      </form>
    </div>
  );

  const areaInfoDiv = (
    <div>
      <div>
        <Typography>Área total: {areaInfo ? areaInfo.area : "0"} ha</Typography>
        <Typography>Latitude: {areaInfo ? areaInfo.latitude : "0"}</Typography>
        <Typography>
          Longitude: {areaInfo ? areaInfo.longitude : "0"}
        </Typography>
      </div>
    </div>
  );

  const creditsInfoDiv = (
    <div>
      <Typography>Créditos: {credits ? credits.toFixed(2) : 0}</Typography>
      <Typography>
        Créditos após conversão: {credits ? (credits - price).toFixed(2) : 0}
      </Typography>
      <Typography>
        Preço calculado: {price ? Math.abs(price).toFixed(2) : 0}
      </Typography>
    </div>
  );

  const resetProject = () => {
    setAreaInfo(null);
    setProjectName(null);
    setInputFile(null);
    setAreaInfo(null);
    setPrice(0);
    setPrices(null);
    setIrpNewName(null);
  };

  const renderLeftLegend = () => {
    return (
      <div style={legendBaseStyle}>
        {areaInfo ? (
          <div>
            <Typography variant="h5">
              {projectName ? projectName : ""}
              <CancelIcon
                onClick={resetProject}
                style={{ float: "right", cursor: "pointer" }}
              />
            </Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <div style={{ color: "black" }}>
              {areaInfoDiv}
              <Divider style={{ margin: "0.5rem 0" }} />
              {creditsInfoDiv}
              <Divider style={{ margin: "0.5rem 0" }} />
              <Combos
                combos={prices}
                area={areaInfo.area}
                combosChanged={(combos) => setPrices(combos)}
                priceChanged={(newPrice) => setPrice(newPrice)}
              />
              <Divider style={{ margin: "0.5rem 0" }} />
              <div style={{ justifyContent: "center", textAlign: "center" }}>
                <Button
                  onClick={runFinal}
                  variant="contained"
                  color="primary"
                  disabled={price === 0 || credits < price}
                >
                  Enviar projeto
                </Button>
              </div>
            </div>
          </div>
        ) : (
          inputLegend
        )}
      </div>
    );
  };

  const onFieldRename = (firstName, newName) => {
    setFields(
      fields.map((field) => {
        if (field.firstName == firstName)
          field.newName = newName.replace(/[^\w\s]/gi, "").replace(/ /g, "");
        return field;
      })
    );
  };

  const renderRightLegend = () => {
    return (
      <div style={rightLegendStyle}>
        {areaInfo ? (
          <div>
            <FieldsTable
              onFieldRename={onFieldRename}
              selectedField={selectedField}
              onSelectField={(firstName) => {
                setSelectedField(firstName);
              }}
              fields={fields}
            />
          </div>
        ) : (
          <ProjectsList
            onError={(err) => {
              setError(err);
            }}
            projects={projects}
          />
        )}
      </div>
    );
  };

  const renderLegend = (legendRef, legendContent, position) => {
    if (map && mapProps) {
      const legend = legendRef.current;
      const { google } = mapProps;
      ReactDOM.render(legendContent, legend, () => {});
      map.controls[google.maps.ControlPosition[position]].push(legend);
    }
  };

  const updateLegend = (legendRef, legendContent) => {
    if (map && mapProps) {
      const legend = legendRef.current;
      ReactDOM.render(legendContent, legend, () => {});
    }
  };

  const onMapReady = (mapProps, map) => {
    setMap(map);
    setMapProps(mapProps);
  };

  const mapDivStyle = () => {
    return {
      marginTop: "64px",
      overflowX: "hidden",
      width: props.sidebarStatus == true ? `calc(100% - 240px)` : "100%",
    };
  };

  const mapStyle = () => {
    return {
      right: "24px",
      width: props.sidebarStatus == true ? `calc(100% - 240px)` : "100%",
      height: `calc(100% - 64px)`,
      position: "relative",
    };
  };

  useEffect(() => {
    if (inputRef) {
      renderLegend(inputRef, renderLeftLegend(), "LEFT_TOP");
      renderLegend(projectsRef, renderRightLegend(), "RIGHT_TOP");
    }
  }, [map, mapProps]);

  useEffect(() => {
    if (inputRef) {
      updateLegend(inputRef, renderLeftLegend());
    }
  }, [inputFile, projectName, areaInfo, prices, price]);

  useEffect(() => {
    updateLegend(projectsRef, renderRightLegend());
    if (map) {
      map.data.setStyle((feature) => {
        let style = {};
        feature.j.name === selectedField
          ? (style = selectedFieldColors)
          : (style = defaultFieldColors);
        return style;
      });
    }
  }, [areaInfo, fields, selectedField]);

  useEffect(() => {
    updateLegend(projectsRef, renderRightLegend());
  }, [projects]);

  useEffect(() => {
    getProjectsList();
  }, []);

  return (
    <div style={mapDivStyle()} id="mapDiv">
      <Snackbar open={error !== null} onClose={() => setError(null)}>
        <MuiAlert variant="filled" severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
      <Snackbar open={uploading}>
        <div
          style={{
            width: "25rem",
            backgroundColor: "whitesmoke",
            padding: "0.5rem",
            borderRadius: "4px",
          }}
        >
          <LinearProgress variant="determinate" value={uploadProgress} />
        </div>
      </Snackbar>
      <Map
        id="map"
        style={mapStyle()}
        google={props.google}
        mapType={"satellite"}
        mapRef={mapRef}
        disableDefaultUI={true}
        onReady={onMapReady}
        zoom={12}
        initialCenter={{ lat: -22.725, lng: -47.6476 }}
      ></Map>
      <div ref={inputRef}></div>
      <div ref={projectsRef}></div>
    </div>
  );
}

export default GoogleApiWrapper((props) => ({
  apiKey: "AIzaSyAs9r64C1yfZ3a_7MexGnI4j2o3_lGHoO8",
}))(MapContainer);
