import Table from "../../components/Table";
import FormModal from "../../components/FormModal";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "../../api";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useForm } from "../../useForm";

const useStyles = makeStyles((theme) => ({
  inputs: {
    minWidth: "380px",
    maxWidth: "600px",
    margin: "1rem 0",
  },
}));

export default function Users(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [userModal, setUserModal] = useState(false);
  const [user, setUser] = useForm({
    email: "",
    name: "",
    password: "",
    level: 1,
    company: "",
  });

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nome",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "E-mail",
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Empresa",
    },
    {
      id: "level",
      numeric: true,
      disablePadding: false,
      label: "Cargo",
    },
  ];

  const getUsers = () => {
    let query = "";
    if (selectedCompany.length == 0) {
      query = "/users";
    } else {
      query = `/users?company=${selectedCompany}`;
    }
    axios
      .get(api.url + query, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getCompanies = () => {
    axios
      .get(api.url + "/companies", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSelected = (ids) => {
    axios({
      method: "delete", //you can set what request you want to be
      url: api.url + "/users",
      data: { ids: ids },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => getUsers())
      .catch((err) => console.log("ERROR", err));
  };

  const toggleNewUserModal = () => {
    setUserModal(!userModal);
  };

  const addUser = () => {
    axios({
      method: "post", //you can set what request you want to be
      url: api.url + "/users",
      data: user,
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        getUsers();
        setUserModal(false);
      })
      .catch((err) => console.log("ERROR", err));
  };

  const renderCompanySelect = () => {
    if (selectedCompany.length > 0) {
      return <div></div>;
    } else {
      return (
        <FormControl className={classes.formControl} className={classes.inputs}>
          <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="company"
            value={user.company}
            onChange={setUser}
            required
          >
            {companies.map((c, idx) => {
              return (
                <MenuItem key={idx} value={c.name}>
                  {c.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
  };

  const filterCompany = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          Filtrar por empresa
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="company"
          value={selectedCompany}
          onChange={(e) => {
            setSelectedCompany(e.target.value);
          }}
          style={{ minWidth: "200px" }}
        >
          <MenuItem value={""}>Todas</MenuItem>
          {companies.map((c, idx) => {
            return (
              <MenuItem key={idx} value={c.name}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const newUserContent = () => {
    return (
      <div>
        <TextField
          type="text"
          name="name"
          placeholder="Nome"
          className={classes.inputs}
          value={user.name}
          onChange={setUser}
          required
        />
        <br />
        <TextField
          type="email"
          name="email"
          placeholder="E-mail"
          className={classes.inputs}
          value={user.email}
          onChange={setUser}
          required
        />
        <br />
        <TextField
          type="password"
          name="password"
          placeholder="Senha"
          className={classes.inputs}
          value={user.password}
          onChange={setUser}
          autoComplete="off"
          required
        />
        <br />
        <FormControl className={classes.formControl} className={classes.inputs}>
          <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="level"
            value={user.level}
            onChange={setUser}
            required
          >
            <MenuItem value={1}>Usuário</MenuItem>
            <MenuItem value={2}>Gerente</MenuItem>
          </Select>
        </FormControl>
        <br />
        {renderCompanySelect()}
      </div>
    );
  };

  useEffect(() => {
    getUsers();
    getCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany.length > 0)
      setUser({ target: { name: "company", value: selectedCompany } });
    getUsers();
  }, [selectedCompany]);

  return (
    <div>
      <FormModal
        open={userModal}
        title="Adicionar usuário"
        content={newUserContent()}
        handleSubmit={addUser}
        actionButtonText="Adicionar"
        handleClose={() => setUserModal(false)}
      />
      <Table
        title={
          <PersonAddIcon
            onClick={toggleNewUserModal}
            style={{ color: "whitesmoke" }}
          />
        }
        data={users.map((user) => {
          if (user.level == 1) user.level = "Usuário";
          else if (user.level == 2) user.level = "Gerente";
          else if (user.level == 3) user.level = "Adm. Op.";
          return user;
        })}
        headCells={headCells}
        deleteSelected={deleteSelected}
        filterInput={filterCompany()}
      />
    </div>
  );
}
