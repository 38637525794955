import Table from "../../components/Table";
import { useState, useEffect } from "react";
import axios from "axios";
import api from "../../api";

function TutorialsContent() {
  const [tutorials, setTutorials] = useState([]);
  const [tutorialModal, setTutorialModal] = useState(false);

  const getTutorials = () => {
    axios
      .get(api.url + "/tutorials", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data.tutorials);
        setTutorials(response.data.tutorials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const headCells = [
    {
      id: "filename",
      numeric: false,
      disablePadding: true,
      label: "Arquivo",
    },
    {
      id: "area",
      numeric: false,
      disablePadding: true,
      label: "Área",
    },
    {
      id: "localization",
      numeric: false,
      disablePadding: true,
      label: "Localização",
    },
  ];

  const addNewTutorial = (e) => {
    let form = new FormData();
    form.append("inputFile", e.target.files[0]);
    axios({
      method: "post", //you can set what request you want to be
      url: api.url + "/tutorials",
      data: form,
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        getTutorials();
      })
      .catch((err) => console.log("ERROR", err));
  };

  const deleteSelectedTutorials = (ids) => {
    axios({
      method: "delete", //you can set what request you want to be
      url: api.url + "/tutorials",
      data: { ids },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        getTutorials();
      })
      .catch((err) => console.log("ERROR", err));
  };

  useEffect(() => {
    getTutorials();
  }, []);

  return (
    <div>
      <Table
        title={
          <div>
            <input
              id="inputFile"
              type="file"
              name="inputFile"
              accept=".ep2, .zip"
              style={{ display: "none" }}
              onChange={addNewTutorial}
              required
            />
            <label
              for="inputFile"
              class="input-file-label"
              style={{
                backgroundColor: "#17b978",
                padding: "0.25rem 0.5rem",
                fontSize: "18px",
                color: "black",
                cursor: "pointer",
              }}
            >
              Enviar arquivo
            </label>
          </div>
        }
        data={tutorials.map((t) => {
          t.localization = `(${t.latitude}, ${t.longitude})`;
          return t;
        })}
        headCells={headCells}
        deleteSelected={deleteSelectedTutorials}
      />
    </div>
  );
}

export default TutorialsContent;
