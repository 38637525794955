import SortTable from "../../components/SortTable";
import { useState, useEffect } from "react";
import api from "../../api";
import axios from "axios";
import { parseISODate } from "../../utils";

const headCells = [
  {
    id: "userEmail",
    numeric: false,
    disablePadding: false,
    label: "Usuário",
  },
  {
    id: "userCompany",
    numeric: false,
    disablePadding: false,
    label: "Empresa",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Data",
    date: true,
  },
  {
    id: "area",
    numeric: true,
    disablePadding: false,
    label: "Área (ha)",
  },
  {
    id: "localization",
    numeric: false,
    disablePadding: false,
    label: "Localização",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
  },
];

export default function Activities() {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    axios
      .get(api.url + "/activities/company/session", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setActivities(
          response.data.activities.map((act) => {
            return act;
          })
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <div>
      <SortTable
        title="Áreas processadas"
        data={activities.map((act) => {
          act.localization = `(${act.latitude}, ${act.longitude})`;
          return act;
        })}
        headCells={headCells}
      />
    </div>
  );
}
