import Sidebar from "../components/Sidebar";
import DownloadsTable from "./DownloadsTable";
import { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import * as FileDownload from "js-file-download";
import { parseISODate } from "../utils";

const headCells = [
  {
    id: "outputname",
    name: "Nome do arquivo",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "type",
    name: "Tipo de processamento",
  },
  {
    id: "date",
    name: "Data",
  },
];

function Downloads(props) {
  const [downloads, setDownloads] = useState([]);

  const getDownloads = () => {
    axios
      .get(api.url + "/downloads", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setDownloads(
          response.data.downloads.map((download) => {
            download.date = parseISODate(download.date);
            return download;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadFile = (data) => {
    axios
      .get(api.url + `/${data.type.split("/")[0]}/download/${data.filename}`, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
        responseType: "blob",
      })
      .then((response) => {
        FileDownload(response.data, data.outputname);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    getDownloads();
  }, []);

  const renderContent = () => {
    return (
      <div>
        <DownloadsTable
          data={downloads}
          header={headCells}
          downloadFile={downloadFile}
        />
      </div>
    );
  };

  return (
    <div>
      <Sidebar
        active={"Downloads"}
        dashboardButtons={[]}
        content={renderContent()}
      />
    </div>
  );
}

export default Downloads;
