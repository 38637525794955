import { BrowserRouter as Router } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import LoginPage from "./Login/Login";
import MasterPage from "./Master/Master";
import ManagerPage from "./Manager/Manager";
import ChangePasswordPage from "./ChangePassword/ChangePassword";
import DownloadsPage from "./Downloads/Downloads";
import IRManagerPage from "./IRManager/IRManager";
import IRPlusPage from "./IRPlus/IRPlus";
import { PrivateRoute } from "./components/PrivateRoute";
import UserPage from "./User/User";
import ResetPasswordPage from "./ResetPassword/ResetPassword";

function App() {
  return (
    <Router>
      <Route exact path="/" component={LoginPage}></Route>
      <Route exact path="/auth/login" component={LoginPage}></Route>
      <Route
        exact
        path="/auth/reset-password"
        component={ResetPasswordPage}
      ></Route>
      <PrivateRoute
        exact
        path="/auth/change-password"
        component={ChangePasswordPage}
      />
      <PrivateRoute exact path="/downloads" component={DownloadsPage} />
      <PrivateRoute exact path="/irmanager" component={IRManagerPage} />
      <PrivateRoute exact path="/irplus" component={IRPlusPage} />
      <PrivateRoute exact path="/dashboard" level={1} component={UserPage} />
      <PrivateRoute exact path="/manager" level={2} component={ManagerPage} />
      <PrivateRoute exact path="/master" level={4} component={MasterPage} />
    </Router>
  );
}

export default App;
