import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import api from "../../api";
import axios from "axios";
import BasicTable from "../../components/BasicTable";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Modal from "../../components/FormModal";
import TextField from "@material-ui/core/TextField";
import { parseISODate } from "../../utils";

const GreenSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#17b978",
    },
    "&$checked + $track": {
      backgroundColor: "#17b978",
    },
  },
  checked: {},
  track: {},
})(Switch);

function CompanyContent(props) {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [activities, setActivities] = useState([]);
  const [companyModal, setCompanyModal] = useState(false);
  const [newCompany, setNewCompany] = useState("");

  const getCompanies = () => {
    axios
      .get(api.url + "/companies", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getActivities = () => {
    if (selectedCompany != null) {
      axios
        .get(api.url + `/activities?userCompany=${selectedCompany.name}`, {
          headers: {
            "access-token": localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setActivities(
            response.data.activities.map((act) => {
              act.date = parseISODate(act.date);
              act.localization = `(${act.latitude}, ${act.longitude})`;
              return act;
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleCompany = () => {
    axios({
      method: "put",
      url: api.url + `/companies/${selectedCompany._id}`,
      data: { active: !selectedCompany.active },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setSelectedCompany({
          ...selectedCompany,
          active: !selectedCompany.active,
        });
        getCompanies();
      })
      .catch((err) => console.log("ERROR", err));
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getActivities();
  }, [selectedCompany]);

  const renderCompanyInfo = () => {
    if (selectedCompany != null) {
      return (
        <div>
          <div style={{ height: "2rem" }}>
            <Typography
              variant="h5"
              style={{ display: "inline-block", margin: 0 }}
            >
              {selectedCompany.name}
            </Typography>
            <div style={{ float: "right" }}>
              <GreenSwitch
                onChange={toggleCompany}
                checked={selectedCompany.active}
                name="checkedA"
              />
            </div>
          </div>
          <BasicTable
            data={activities}
            header={[
              { id: "userEmail", name: "Usuário" },
              { id: "date", name: "Data" },
              { id: "area", name: "Área" },
              { id: "localization", name: "Localização" },
              { id: "type", name: "Tipo" },
            ]}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const companyModalContent = () => {
    return (
      <div>
        <TextField
          type="text"
          placeholder="Nome"
          value={newCompany}
          onChange={(e) => setNewCompany(e.target.value)}
          style={{ minWidth: "300px", margin: "1rem 0" }}
          required
        />
      </div>
    );
  };

  const handleModalSubmit = () => {
    axios({
      method: "post",
      url: api.url + `/companies`,
      data: { name: newCompany },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        getCompanies();
        setCompanyModal(false);
      })
      .catch((err) => console.log("ERROR", err));
  };

  return (
    <div>
      <Modal
        open={companyModal}
        title="Adicionar empresa"
        handleClose={() => setCompanyModal(false)}
        content={companyModalContent()}
        handleSubmit={handleModalSubmit}
        actionButtonText="Adicionar"
      />
      <Grid container>
        <Grid item xs={3} id="table-side" style={{ maxHeight: "200px" }}>
          <div style={{ height: "2.4rem" }}>
            <AddBoxIcon
              onClick={() => setCompanyModal(true)}
              style={{ color: "whitesmoke", cursor: "pointer" }}
            />
          </div>
          <BasicTable
            data={companies}
            header={[
              {
                id: "name",
                name: "Empresas",
              },
            ]}
            onRowClick={(company) => setSelectedCompany(company)}
            cursor="pointer"
          />
        </Grid>
        <Grid
          item
          xs
          style={{
            display: selectedCompany ? "block" : "none",
            paddingLeft: "4rem",
          }}
        >
          {renderCompanyInfo()}
        </Grid>
      </Grid>
    </div>
  );
}

CompanyContent.propTypes = {};

export default CompanyContent;
