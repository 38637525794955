import axios from "axios";
import api from "./api";

class Auth {
  constructor() {
    this.axios = axios.create({ withCredentials: true });
    this.axios.defaults.headers["Content-Type"] =
      "application/json;charset=utf-8";
    this.axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: api.url + "/auth/check-session",
        data: {},
        headers: {
          "access-token": localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          const user = response.data.data;
          localStorage.setItem("user", JSON.stringify(user));
          resolve(user);
        })
        .catch((err) => {
          localStorage.setItem("user", null);
          localStorage.setItem("token", null);
          localStorage.setItem("refreshToken", null);
          reject(err);
        });
    });
  }

  loginRedirect() {
    return new Promise((resolve, reject) => {
      this.checkSession()
        .then((user) => {
          if (user.level >= 4) {
            resolve("/master");
          } else if (user.level === 2) {
            resolve("/manager");
          } else if (user.level === 1) {
            resolve("/dashboard");
          } else resolve("/irmanager");
        })
        .catch((err) => resolve("/auth/login"));
    });
  }
}

export default new Auth();
