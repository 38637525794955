import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default function SimpleAppBar(props) {
  return (
    <AppBar position="static" style={{ backgroundColor: "#17b978" }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          INFOROW
        </Typography>
        <a
          style={{ textDecoration: "none", color: "whitesmoke" }}
          href="/auth/login"
        >
          <Button color="inherit">Entrar</Button>
        </a>
      </Toolbar>
    </AppBar>
  );
}
