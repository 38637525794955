import Sidebar from "../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "../useForm";
import axios from "axios";
import api from "../api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#17b978",
    margin: "0 auto",
    width: "400px",
    padding: "1rem",
    textAlign: "center",
    color: "#007bff",
    borderRadius: "4px",
  },
  inputs: {
    color: "#07444A",
    width: "90%",
    margin: "1rem 0",
    "& label": {
      color: "#007bff",
    },
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#007bff",
    },
    "& label": {
      color: "#07444A",
    },
  },
}));

function ChangePassword(props) {
  const classes = useStyles();
  const [password, setPassword, reset] = useForm({
    actualPass: "",
    newPass: "",
    repeatNewPass: "",
  });
  const [error, setError] = useState(null);

  const cleanInputs = () => {
    reset();
  };

  const changePassword = (e) => {
    e.preventDefault();
    cleanInputs();
    axios({
      method: "post", //you can set what request you want to be
      url: api.url + "/auth/change-password",
      data: password,
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        const message =
          err.response.data.message || "Erro ao tentar trocar a senha.";
        setError(message);
      });
  };

  const renderContent = () => {
    return (
      <div className={classes.root}>
        <form onSubmit={changePassword}>
          <TextField
            className={classes.inputs}
            type="password"
            name="actualPass"
            value={password.actualPass}
            onChange={setPassword}
            label="Senha atual"
            variant="outlined"
            required
          />
          <TextField
            className={classes.inputs}
            type="password"
            name="newPass"
            value={password.newPass}
            onChange={setPassword}
            label="Nova senha"
            variant="outlined"
            required
          />
          <TextField
            className={classes.inputs}
            type="password"
            name="repeatNewPass"
            value={password.repeatNewPass}
            onChange={setPassword}
            label="Repetir nova senha"
            variant="outlined"
            required
          />
          <Button type="submit" variant="contained" className="blue-button">
            Alterar
          </Button>
        </form>
      </div>
    );
  };

  return (
    <div>
      <Snackbar
        open={error !== null ? true : false}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <MuiAlert variant="filled" severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
      <Sidebar
        active={"Alterar senha"}
        dashboardButtons={[]}
        content={renderContent()}
      />
    </div>
  );
}

export default ChangePassword;
