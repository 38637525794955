import React, { useEffect, useState } from "react";
import auth from "../auth";
import Sidebar from "../components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import axios from "axios";
import api from "../api";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  loginDiv: {
    width: "30%",
    minWidth: "400px",
    margin: "0 auto",
    background: "#17b978",
    color: "whitesmoke",
    textAlign: "center",
    padding: "0.5rem",
    borderRadius: "4px",
  },
  loginTitle: {
    margin: 0,
    padding: 0,
    fontSize: "28px",
    marginBottom: "0.5rem",
  },
  loginInputsDiv: {
    padding: "0.5rem",
    "input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px red inset !important",
    },
  },
  loginInputs: {
    width: "100%",
    marginTop: "0.25rem",
    "& label": {
      color: "whitesmoke",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "whitesmoke",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff",
    },
  },
  inputsText: {
    color: "whitesmoke",
    fontSize: "20px",
    "-webkit-text-fill-color": "whitesmoke !important",
    "‑webkit‑text‑size‑adjust": "100%",
  },
  submitButton: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
}));

export default function Login(props) {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [error, setError] = useState(false);
  const [errorDialog, setErrorDialog] = useState("Algo de errado aconteceu...");

  useEffect(async () => {
    const response = await auth.loginRedirect();
    history.push(response);

    axios({
      method: "get",
      url: api.url + "/users",
      data: {},
      headers: {
        "access-token": localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const doLogin = async (e) => {
    e.preventDefault();
    axios
      .post(api.url + "/auth/login", {
        email,
        password,
      })
      .then(async (response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refresh-token", response.data.refreshToken);
        const redirect = await auth.loginRedirect();
        history.push(redirect);
      })
      .catch((err) => {
        localStorage.setItem("token", null);
        localStorage.setItem("refresh-token", null);
        setErrorDialog(err.response.data.message);
        setError(true);
        console.log(err.response.data);
      });
  };

  const renderContent = () => {
    return (
      <div>
        <Snackbar
          style={{ background: "pink" }}
          open={error}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="error" variant="filled">
            {errorDialog}
          </Alert>
        </Snackbar>
        <div className={classes.loginDiv}>
          <h2 className={classes.loginTitle}>Seja bem-vindo!</h2>
          <Divider />
          <form onSubmit={doLogin}>
            <div className={classes.loginInputsDiv}>
              <TextField
                id="standard-basic"
                label="E-mail"
                type="email"
                className={classes.loginInputs}
                InputProps={{
                  className: classes.inputsText,
                }}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoComplete="off"
              />
              <TextField
                id="standard-basic"
                label="Senha"
                type="password"
                className={classes.loginInputs}
                InputProps={{
                  className: classes.inputsText,
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              Entrar
            </Button>
            <br />
            <a style={{ textDecoration: "none" }} href="/auth/reset-password">
              <Button
                variant="contained"
                color="secondary"
                className={classes.submitButton}
              >
                Esqueci minha senha
              </Button>
            </a>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Sidebar
        defaultOpen={false}
        navButtons={[]}
        dashboardButtons={[]}
        content={renderContent()}
      />
    </div>
  );
}
