import Sidebar from "../components/Sidebar";
import NewMap from "./NewMap";
import { useState } from "react";

function IRManager(props) {
  const [openSidebar, setOpenSidebar] = useState(true);

  const renderContent = () => {
    return (
      <div>
        <NewMap
          sidebarStatus={openSidebar}
          apiKey="AIzaSyAs9r64C1yfZ3a_7MexGnI4j2o3_lGHoO8"
        ></NewMap>
      </div>
    );
  };

  return (
    <div>
      <Sidebar
        active={"IRManager"}
        dashboardButtons={[]}
        content={renderContent()}
        drawerHeader={false}
        defaultOpen={false}
        open={() => setOpenSidebar(true)}
        close={() => setOpenSidebar(false)}
        noContentPadding={true}
      />
    </div>
  );
}

export default IRManager;
