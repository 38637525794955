import React from "react";
import Sidebar from "../components/Sidebar";
import GroupIcon from "@material-ui/icons/Group";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BusinessIcon from "@material-ui/icons/Business";
import MapIcon from "@material-ui/icons/Map";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import DnsIcon from "@material-ui/icons/Dns";
import UsersContent from "./Contents/Users";
import ReloadsContent from "./Contents/Reloads";
import CompaniesContent from "./Contents/Companies";
import AreasContent from "./Contents/Areas";
import TutorialsContent from "./Contents/Tutorials";
require("./master.css");

export default function Master(props) {
  const defaultButton = {
    name: "Áreas",
    icon: <MapIcon />,
    content: <AreasContent />,
  };

  const dashboardButtons = [
    {
      name: "Usuários",
      icon: <GroupIcon />,
      content: <UsersContent />,
    },
    {
      name: "Recargas",
      icon: <MonetizationOnIcon />,
      content: <ReloadsContent />,
    },
    {
      name: "Empresas",
      icon: <BusinessIcon />,
      content: <CompaniesContent />,
    },
    defaultButton,
    {
      name: "Tutoriais",
      icon: <PlaylistAddCheckIcon />,
      content: <TutorialsContent />,
    },
    {
      name: "Servidor",
      icon: <DnsIcon />,
    },
  ];

  return (
    <div>
      <Sidebar
        active={"Dashboard"}
        dashboardButtons={dashboardButtons}
        defaultSelectedButton={defaultButton}
      />
    </div>
  );
}
