import Map from "./Map";
import Sidebar from "../components/Sidebar";
import { useState } from "react";

function IRPlus(props) {
  const [openSidebar, setOpenSidebar] = useState(true);

  const renderContent = () => {
    return (
      <div>
        <Map
          sidebarStatus={openSidebar}
          apiKey="AIzaSyAs9r64C1yfZ3a_7MexGnI4j2o3_lGHoO8"
        ></Map>
      </div>
    );
  };

  return (
    <div>
      <Sidebar
        active={"IRPlus"}
        dashboardButtons={[]}
        content={renderContent()}
        drawerHeader={false}
        defaultOpen={false}
        open={() => setOpenSidebar(true)}
        close={() => setOpenSidebar(false)}
        noContentPadding={true}
      />
    </div>
  );
}

export default IRPlus;
