import SimpleAppBar from "../components/SimpleAppBar";
import LockIcon from "@material-ui/icons/Lock";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import { useForm } from "../useForm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import auth from "../auth";

const mainDivStyle = {
  margin: "2rem auto",
  width: "30%",
  minWidth: "300px",
  textAlign: "center",
  backgroundColor: "#17b978",
  padding: "1rem",
  borderRadius: "4px",
  boxShadow: "2px 3px #05422a",
};

const lockIconStyle = {
  color: "whitesmoke",
  display: "inline-block",
  marginLeft: "0.5rem",
};

const inputsStyle = {
  width: "90%",
};

export default function ResetPassword(props) {
  const [tokenSent, setTokenSent] = useState(false);
  const [sendingToken, setSendingToken] = useState(false);
  const [newPassForm, setNewPassForm, resetNewPassForm] = useForm({
    token: null,
    newPassword: "",
    newPasswordConf: "",
  });
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const history = useHistory();

  useEffect(async () => {
    const response = await auth.loginRedirect();
    history.push(response);
  }, []);

  const requestTokenToEmail = (e) => {
    e.preventDefault();
    setSendingToken(true);
    axios({
      method: "post",
      url: api.url + "/auth/reset-password/send-token-to-email",
      data: { email },
      headers: {},
    })
      .then((response) => {
        setTokenSent(true);
        setSendingToken(false);
      })
      .catch((err) => {
        const message =
          err.response.data.message || "Erro ao tentar enviar token.";
        setError(message);
      });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    console.log(newPassForm);
    axios({
      method: "post",
      url: api.url + "/auth/reset-password",
      data: newPassForm,
      headers: {
        "access-token": newPassForm.token,
      },
    })
      .then((response) => {
        resetNewPassForm();
        setInfo(
          "Senha alterada com sucesso. Clique aqui para ser redirecionado para a página de login."
        );
      })
      .catch((err) => {
        const message =
          err.response.data.message || "Erro ao tentar atualizar senha.";
        setError(message);
      });
  };

  const renderInputArea = () => {
    if (tokenSent === false) {
      return (
        <div>
          <Typography
            variant="h5"
            style={{ color: "whitesmoke", display: "inline-block" }}
          >
            Resetar senha
          </Typography>
          <LockIcon style={lockIconStyle} />
          <form onSubmit={requestTokenToEmail}>
            <TextField
              type="email"
              style={inputsStyle}
              label="Seu e-mail"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "1rem" }}
              type="submit"
            >
              {sendingToken === true ? "Carregando..." : "Enviar token"}
            </Button>
          </form>
        </div>
      );
    } else {
      return (
        <div>
          <Typography
            variant="h5"
            style={{ color: "whitesmoke", display: "inline-block" }}
          >
            Um token de verificação foi enviado para o e-mail informado.
          </Typography>
          <form onSubmit={resetPassword}>
            <TextField
              type="text"
              name="token"
              style={inputsStyle}
              label="Token"
              value={newPassForm.token}
              onChange={setNewPassForm}
              required
            />
            <TextField
              type="password"
              name="newPassword"
              style={inputsStyle}
              label="Nova senha"
              value={newPassForm.newPassword}
              onChange={setNewPassForm}
              required
            />
            <TextField
              type="password"
              name="newPasswordConf"
              style={inputsStyle}
              label="Confirmar nova senha"
              value={newPassForm.newPasswordConf}
              onChange={setNewPassForm}
              required
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "1rem" }}
              type="submit"
            >
              Trocar senha
            </Button>
          </form>
        </div>
      );
    }
  };

  return (
    <div style={{ backgroundColor: "#086972", height: "100vh" }}>
      <Snackbar
        open={error !== null ? true : false}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <MuiAlert variant="filled" severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={info !== null ? true : false}
        autoHideDuration={6000}
        onClose={() => setInfo(null)}
      >
        <a href="/auth/login" style={{ textDecoration: "none" }}>
          <MuiAlert variant="filled" severity="info">
            {info}
          </MuiAlert>
        </a>
      </Snackbar>
      <SimpleAppBar />
      <div style={mainDivStyle}>{renderInputArea()}</div>
    </div>
  );
}
