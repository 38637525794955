import React from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";

export default function FormModal(props) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Roboto",
    },
    paper: {
      backgroundColor: props.backgroundColor || theme.palette.background.paper,
      color: props.color || "black",
      border: props.border || "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2 style={{ fontFamily: "sans-serif" }}>{props.title}</h2>
            <Divider />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit(e.target);
              }}
            >
              {props.content}
              <Divider style={{ margin: "1rem 0" }} />
              <Button
                variant="contained"
                type="submit"
                style={{ float: "right" }}
                color="primary"
                disabled={props.submitDisabled ? props.submitDisabled : false}
              >
                {props.actionButtonText || "Enviar"}
              </Button>
              <Button variant="contained" onClick={props.handleClose}>
                Cancelar
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
