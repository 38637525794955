import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import api from "../api";
import axios from "axios";
import * as FileDownload from "js-file-download";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    display: "inline-block",
    flexBasis: "50%",
    flexShrink: 0,
  },
  icons: {
    cursor: "pointer",
    marginLeft: "0.25rem",
    "&:hover, &:focus": {
      color: "#17b978",
    },
  },
}));

const ProjectsList = (props) => {
  const classes = useStyles();

  const downloadFile = (outputFileName) => {
    axios
      .get(api.url + `/irplus/download/${outputFileName}`, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
        responseType: "blob",
      })
      .then((response) => {
        FileDownload(response.data, outputFileName.substring(9));
      })
      .catch((err) => {
        if (props.onError) {
          props.onError("Erro ao tentar baixar zip do projeto.");
        }
      });
  };

  if (props.projects) {
    return (
      <div style={{ maxHeight: "600px", overflowY: "auto" }}>
        <Typography variant="h5">Projetos realizados</Typography>
        <Divider style={{ margin: "0.5rem 0rem" }} />
        {props.projects.map((project) => {
          return (
            <div style={{ margin: "0.5rem 0rem", color: "#121212" }}>
              <Typography className={classes.heading}>
                {project.projectName}
              </Typography>
              {project.status === "processing" ? (
                <CircularProgress
                  style={{
                    display: "inline-block",
                    float: "right",
                    marginRight: "1rem",
                  }}
                  color="inherit"
                  size={20}
                />
              ) : (
                <div
                  style={{
                    display: "inline-block",
                    float: "right",
                    marginRight: "1rem",
                  }}
                >
                  <EditIcon className={classes.icons} />
                  <DownloadIcon
                    className={classes.icons}
                    onClick={(e) => downloadFile(project.outputFileName)}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div>Projects not informed.</div>;
  }
};

export default ProjectsList;
