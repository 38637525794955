import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

export default function ComboSelector(props) {
  const [key, setKey] = useState(null);
  const [parent, setParent] = useState(null);
  const [childs, setChilds] = useState(null);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (key && parent && childs) {
      props.comboChanged(key, parent, childs);
      const oldPrice = price;
      if (props.priceChanged) {
        if (parent.selected === true) {
          props.priceChanged(parent.price, oldPrice);
          setPrice(parent.price);
        } else {
          let childsPrice = 0;
          childs.forEach((child) => {
            if (child.selected === true) childsPrice += child.price;
          });
          props.priceChanged(childsPrice, oldPrice);
          setPrice(childsPrice);
        }
      }
    }
  }, [childs, parent]);

  useEffect(() => {
    if (props.parentIndex) {
      setKey(props.parentIndex);
    }
    if (childs) {
      const childsCount = childs.length;
      let childsSelected = 0;
      childs.forEach((child) => {
        if (child.selected === true) childsSelected += 1;
      });
      if (childsSelected === childsCount) {
        setParent({ ...parent, selected: true });
        setChilds(
          childs.map((c) => {
            c.selected = false;
            return c;
          })
        );
      }
    }
  }, [childs]);

  useEffect(() => {
    if (props.key) setKey(props.key);
    if (props.combo) {
      setParent({
        label: props.combo.label,
        price: props.combo.price,
        selected: false,
      });
      const childsKeys = Object.keys(props.combo.childs);
      setChilds(
        childsKeys.map((child) => {
          props.combo.childs[child].selected = false;
          return props.combo.childs[child];
        })
      );
    }
  }, []);

  const render = () => {
    if (parent && childs) {
      const renderParent = (
        <Typography style={{ marginTop: "1rem" }}>
          <Checkbox
            style={{ padding: 0, marginRight: "0.5rem" }}
            id={parent.label}
            color="primary"
            onChange={(e) => {
              setParent({ ...parent, selected: e.target.checked });
              setChilds(
                childs.map((c) => {
                  c.selected = false;
                  return c;
                })
              );
            }}
            checked={parent.selected}
          />
          <label htmlFor={parent.label}>
            {parent.label} - {parent.price} créditos/ha
          </label>
        </Typography>
      );

      const renderChilds = (
        <div style={{ marginLeft: "1rem" }}>
          {childs.map((child) => {
            return (
              <div>
                <Typography>
                  <Checkbox
                    style={{ padding: 0, marginRight: "0.5rem" }}
                    id={child.label}
                    color="primary"
                    onChange={(e) => {
                      setChilds(
                        childs.map((c) => {
                          if (c.label === child.label)
                            c.selected = e.target.checked;
                          return c;
                        })
                      );
                    }}
                    checked={child.selected || parent.selected}
                  />
                  <label htmlFor={child.label}>
                    {child.label} - {child.price} créditos/ha
                  </label>
                </Typography>
              </div>
            );
          })}
        </div>
      );

      return (
        <div>
          {renderParent}
          {renderChilds}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return <div>{render()}</div>;
}
