import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Grid from "@material-ui/core/Grid";
import SortTable from "../components/SortTable";
import axios from "axios";
import api from "../api";
import { Doughnut } from "react-chartjs-2";

export default function User(props) {
  const [activities, setActivities] = useState([]);
  const [irplusCount, setIrplusCount] = useState(0);
  const [irmanagerCount, setIrmanagerCount] = useState(0);

  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      date: true,
      label: "Data",
    },
    {
      id: "area",
      numeric: true,
      disablePadding: false,
      label: "Área (ha)",
    },
    {
      id: "localization",
      numeric: false,
      disablePadding: false,
      label: "Localização",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
    },
  ];

  const userDashboardContent = () => {
    const data = {
      labels: ["IRPlus", "IRManager"],
      datasets: [
        {
          label: "Consumo",
          data: [irplusCount, irmanagerCount],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)",
          ],
          borderColor: [
            "rgb(255, 99, 132, 1)",
            "rgb(54, 162, 235, 1)",
            "rgb(255, 205, 86, 1)",
          ],
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          labels: {
            color: "#f7f7f7",
          },
        },
      },
    };

    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <SortTable
            title="Suas atividades"
            data={activities}
            headCells={headCells}
          />
        </Grid>
        <Grid item xs={3}>
          <Doughnut data={data} options={options} />
        </Grid>
      </Grid>
    );
  };

  const getActivities = () => {
    axios
      .get(api.url + "/activities/user/session", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        let irpCount = 0;
        let irmCount = 0;
        response.data.activities.forEach((act) => {
          const type = act.type.toLowerCase();
          if (type === "irmanager") irmCount += 1;
          else if (type === "irplus") irpCount += 1;
        });
        setIrmanagerCount(irmCount);
        setIrplusCount(irpCount);
        setActivities(response.data.activities);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getActivities();
  }, []);

  return (
    <div>
      <Sidebar
        active={"Dashboard"}
        dashboardButtons={[]}
        content={userDashboardContent()}
      />
    </div>
  );
}
