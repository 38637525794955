import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

function BasicTable({ data, header, rowHover, onRowClick, cursor, maxHeight }) {
  const useStyles = makeStyles({
    table: {
      border: "none",
    },
    tableHead: {
      background: "#17b978",
      color: "#07444A",
    },
    tableBody: {
      background: "#07444A",
    },
    tableRow: {
      cursor: cursor,
    },
  });
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ maxHeight }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {header.map((h) => {
              return (
                <TableCell
                  align="center"
                  style={{ borderBottom: "1px solid #052B2E" }}
                >
                  {h.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {data.map((row) => (
            <TableRow
              onClick={() => onRowClick(row)}
              hover={rowHover}
              key={row.name}
              className={classes.tableRow}
            >
              {header.map((h) => {
                return (
                  <TableCell
                    align="center"
                    style={{
                      color: "whitesmoke",
                      borderBottom: "1px solid #052B2E",
                    }}
                  >
                    {row[h.id]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  rowHover: PropTypes.bool,
  onRowClick: PropTypes.func,
  cursor: PropTypes.string,
  maxHeight: PropTypes.string,
};

BasicTable.defaultProps = {
  data: [],
  header: [],
  rowHover: true,
  onRowClick: (data) => console.log(data),
  cursor: "default",
  maxHeight: "600px",
};

export default BasicTable;
