import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import AddBoxIcon from "@material-ui/icons/AddBox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import api from "../../api";
import FormModal from "../../components/FormModal";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "../../useForm";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as FileDownload from "js-file-download";

const useStyles = makeStyles((theme) => ({
  inputs: {
    minWidth: "380px",
    maxWidth: "600px",
    margin: "1rem 0",
  },
}));

export default function Reloads(props) {
  const classes = useStyles();
  const [reloads, setReloads] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [reloadModal, setReloadModal] = useState(false);
  const [newReload, setNewReload] = useForm({
    value: 0,
    validUntil: "",
    company: "",
    type: "",
  });

  const getCompanies = () => {
    axios
      .get(api.url + "/companies", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCompanies(response.data.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReloads = () => {
    let query = "";
    if (selectedCompany.length > 0) query = `?company=${selectedCompany}`;
    axios
      .get(api.url + "/reloads" + query, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setReloads(
          response.data.reloads.map((reload) => {
            //reload.date = parseISODate(reload.date);
            //reload.validUntil = parseISODate(reload.validUntil);
            return reload;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSelected = (ids) => {
    axios
      .delete(api.url + "/reloads", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
        data: {
          ids,
        },
      })
      .then(() => {
        getReloads();
      });
  };

  const getReloadsCSV = () => {
    console.log("GET RELOADS CSV");
    let query = "";
    if (selectedCompany.length > 0) query = `?company=${selectedCompany}`;
    axios
      .get(api.url + "/reloads/csv" + query, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        FileDownload(response.data, "Recargas.csv");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filters = () => {
    return (
      <FormControl>
        <InputLabel id="demo-simple-select-label">
          Filtrar por empresa
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="company"
          value={selectedCompany}
          onChange={(e) => {
            setSelectedCompany(e.target.value);
          }}
          style={{ minWidth: "200px" }}
        >
          <MenuItem value={""}>Todas</MenuItem>
          {companies.map((c, idx) => {
            return (
              <MenuItem key={idx} value={c.name}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    getCompanies();
    getReloads();
  }, []);

  useEffect(() => {
    getReloads();
    setNewReload({ target: { name: "company", value: selectedCompany } });
  }, [selectedCompany]);

  const openReloadModal = () => {
    setReloadModal(true);
  };

  const renderSelectCompany = () => {
    return (
      <FormControl className={classes.formControl} className={classes.inputs}>
        <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="company"
          value={newReload.company}
          onChange={setNewReload}
          required
        >
          {companies.map((c, idx) => {
            return (
              <MenuItem key={idx} value={c.name}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const reloadModalContent = () => {
    return (
      <div>
        <TextField
          type="number"
          name="value"
          placeholder="Valor"
          className={classes.inputs}
          defaultValue={0}
          value={newReload.value}
          onChange={setNewReload}
          required
        />
        <br />
        <TextField
          name="validUntil"
          label="Data de vencimento"
          type="date"
          className={classes.inputs}
          onChange={setNewReload}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <br />
        <FormControl className={classes.formControl} className={classes.inputs}>
          <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="type"
            value={newReload.type}
            onChange={setNewReload}
            required
          >
            <MenuItem value={"Venda"}>Venda</MenuItem>
            <MenuItem value={"Conversão"}>Conversão</MenuItem>
            <MenuItem value={"Demonstração"}>Demonstração</MenuItem>
          </Select>
        </FormControl>
        <br />
        {renderSelectCompany()}
      </div>
    );
  };

  const addReload = () => {
    axios
      .post(
        api.url + "/reloads",
        { ...newReload, value: parseInt(newReload.value) },
        {
          headers: {
            "access-token": localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        getReloads();
        setReloadModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <FormModal
        open={reloadModal}
        title="Adicionar recarga"
        content={reloadModalContent()}
        actionButtonText="Adicionar"
        handleClose={() => {
          setReloadModal(false);
        }}
        handleSubmit={addReload}
      />
      <Table
        title={
          <div>
            <AddBoxIcon
              onClick={openReloadModal}
              style={{ color: "whitesmoke" }}
            />
            <GetAppIcon
              onClick={getReloadsCSV}
              style={{ color: "whitesmoke", marginLeft: "2rem" }}
            />
          </div>
        }
        deleteSelected={deleteSelected}
        filterInput={filters()}
        data={reloads}
        headCells={[
          {
            id: "company",
            numeric: false,
            disabblePadding: true,
            label: "Empresa",
          },
          {
            id: "value",
            numeric: true,
            disabblePadding: false,
            label: "Valor total",
          },
          {
            id: "availableValue",
            numeric: true,
            disabblePadding: false,
            label: "Valor atual",
          },
          {
            id: "type",
            numeric: false,
            disabblePadding: false,
            label: "Tipo",
          },
          {
            id: "date",
            numeric: false,
            disabblePadding: false,
            label: "Data",
            date: true,
          },
          {
            id: "validUntil",
            numeric: false,
            disabblePadding: false,
            label: "Validade",
            date: true,
          },
        ]}
      />
    </div>
  );
}
