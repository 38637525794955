import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../auth";

export const PrivateRoute = ({ component: Component, level, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = JSON.parse(localStorage.getItem("user"));
      //Se o usuário não está logado, redirecionar para página de login.
      if (!user) {
        return (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        );
      } else {
        if (level) {
          if (user.level === level) return <Component {...props} />;
          else return <Redirect to={{ pathname: "/auth/login" }} />;
        } else {
          return <Component {...props} />;
        }
      }
    }}
  />
);
