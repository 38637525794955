import SortTable from "../../components/SortTable";
import axios from "axios";
import api from "../../api";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import * as FileDownload from "js-file-download";

const headCells = [
  {
    id: "userEmail",
    numeric: false,
    disablePadding: false,
    label: "Usuário",
  },
  {
    id: "userCompany",
    numeric: false,
    disablePadding: false,
    label: "Empresa",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Data",
    date: true,
  },
  {
    id: "area",
    numeric: true,
    disablePadding: false,
    label: "Área (ha)",
  },
  {
    id: "localization",
    numeric: false,
    disablePadding: false,
    label: "Localização",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
  },
];

function AreasContent() {
  const [activities, setActivities] = useState([]);

  const getAreas = () => {
    let query = "";
    axios
      .get(api.url + "/activities" + query, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setActivities(
          response.data.activities.map((area) => {
            return area;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadAreasCSV = () => {
    let query = "";
    axios
      .get(api.url + "/activities/csv" + query, {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        FileDownload(response.data, "Atividades.csv");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAreas();
  }, []);

  return (
    <div>
      <SortTable
        title="Áreas processadas"
        data={activities.map((act) => {
          act.localization = `(${act.latitude}, ${act.longitude})`;
          return act;
        })}
        headCells={headCells}
      />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={downloadAreasCSV}
          variant="contained"
          className="green-buttons"
        >
          Download as CSV
        </Button>
      </div>
    </div>
  );
}

export default AreasContent;
