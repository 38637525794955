import React from "react";
import Sidebar from "../components/Sidebar";
import GroupIcon from "@material-ui/icons/Group";
import MapIcon from "@material-ui/icons/Map";
import MoneyIcon from "@material-ui/icons/MonetizationOn";
import AreasContent from "./Contents/Areas";
import UsersContent from "./Contents/Users";
import ReloadsContent from "./Contents/Reloads";

export default function Manager(props) {
  const defaultButton = {
    name: "Atividades",
    icon: <MapIcon />,
    content: <AreasContent />,
  };

  const dashboardButtons = [
    {
      name: "Usuários",
      icon: <GroupIcon />,
      content: <UsersContent />,
    },
    defaultButton,
    {
      name: "Recargas",
      icon: <MoneyIcon />,
      content: <ReloadsContent />,
    },
  ];

  return (
    <div>
      <Sidebar
        active={"Dashboard"}
        dashboardButtons={dashboardButtons}
        defaultSelectedButton={defaultButton}
      />
    </div>
  );
}
