import ComboSelector from "./ComboSelector";
import { useState, useEffect } from "react";

const Combos = (props) => {
  const [combos, setCombos] = useState([]);
  const [area, setArea] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (props.combos) setCombos(props.combos);
    if (props.area) setArea(props.area);
  }, []);

  useEffect(() => {
    if (combos) props.combosChanged(combos);
  }, [combos]);

  useEffect(() => {
    props.priceChanged(price);
  }, [price]);

  if (props.combos && props.area) {
    const priceChanged = (value, oldValue) => {
      const newValue = value - oldValue;
      setPrice(price + area * newValue);
    };

    const comboChanged = (parentKey, parent, childs) => {
      parent.childs = childs;
      setCombos({ ...combos, [parentKey]: parent });
    };

    return (
      <div>
        {Object.keys(combos).map((combo) => {
          const index = combo;
          return (
            <ComboSelector
              parentIndex={index}
              combo={combos[combo]}
              comboChanged={comboChanged}
              priceChanged={priceChanged}
            />
          );
        })}
      </div>
    );
  } else return <div>Props not informed.</div>;
};

export default Combos;
