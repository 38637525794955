import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const fieldsTable = (props) => {
  if (props.fields) {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome antigo</TableCell>
              <TableCell>Novo Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map((field) => {
              return (
                <TableRow
                  style={{
                    backgroundColor:
                      field.firstName == props.selectedField
                        ? "#17b978"
                        : "whitesmoke",
                    cursor: "pointer",
                  }}
                  onClick={() => props.onSelectField(field.firstName)}
                >
                  <TableCell>
                    <Typography>{field.firstName}</Typography>
                  </TableCell>
                  {field.firstName == props.selectedField ? (
                    <TableCell>
                      <TextField
                        value={field.newName}
                        onChange={(e) => {
                          props.onFieldRename(field.firstName, e.target.value);
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Typography>{field.newName}</Typography>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  } else {
    return <div>Fields not informed.</div>;
  }
};

export default fieldsTable;
