import SortTable from "../../components/SortTable";
import { useState, useEffect } from "react";
import axios from "axios";
import api from "../../api";
import { parseISODate } from "../../utils";

function ReloadsContent() {
  const [reloads, setReloads] = useState([]);

  const getReloads = () => {
    axios
      .get(api.url + "/reloads/company/session", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setReloads(
          response.data.reloads.map((reload) => {
            return reload;
          })
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getReloads();
  }, []);

  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
      date: true,
    },
    {
      id: "value",
      numeric: true,
      disablePadding: false,
      label: "Valor total",
    },
    {
      id: "validUntil",
      numeric: false,
      disablePadding: false,
      label: "Data de validade",
      date: true,
    },
    {
      id: "availableValue",
      numeric: true,
      disablePadding: false,
      label: "Valor disponível",
    },
  ];

  return (
    <div>
      <SortTable title="Recargas" data={reloads} headCells={headCells} />
    </div>
  );
}

export default ReloadsContent;
