import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import ExitIcon from "@material-ui/icons/ExitToApp";
import auth from "../auth";
import AddBoxIcon from "@material-ui/icons/AddBox";

const drawerWidth = 240;

export default function Sidebar(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "100vh",
      background: "#086972",
      color: "whitesmoke",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: "#17B978",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: "#07444A",
      color: "whitesmoke",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: props.noContentPadding ? theme.spacing(0) : theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    dashboardIcons: {
      color: "whitesmoke",
      float: "right !important",
      fontSize: "20px",
    },
    dashboardActiveIcon: {
      color: "#17b978",
      float: "right !important",
      fontSize: "20px",
    },
    dashboardActiveText: {
      color: "#17b978",
    },
    dashboardText: {
      color: "whitesmoke",
    },
  }));

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [logged, setLogged] = React.useState(true);
  const [activeDashIcon, setActiveDashIcon] = React.useState(null);

  const redirect = (url) => {
    history.push(url);
  };

  let navButtons = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      active: true,
      action: () => redirect("/"),
    },
    {
      name: "IRManager",
      icon: <LineStyleIcon />,
      action: () => redirect("/irmanager"),
    },
    {
      name: "IRPlus",
      icon: <AddBoxIcon />,
      action: () => redirect("/irplus"),
    },
    {
      name: "Downloads",
      icon: <CloudDownloadIcon />,
      action: () => redirect("/downloads"),
    },
    {
      name: "Alterar senha",
      icon: <EditIcon />,
      action: () => redirect("/auth/change-password"),
    },
    {
      name: "Sair",
      icon: <ExitIcon />,
      action: () => {
        localStorage.setItem("token", null);
        localStorage.setItem("refreshToken", null);
        redirect("/auth/login");
      },
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
    if (props.open) {
      props.open();
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    if (props.close) {
      props.close();
    }
  };

  useEffect(() => {
    if (props.defaultSelectedButton) {
      setActiveDashIcon(props.defaultSelectedButton);
    }
    if (props.defaultOpen === true) {
      handleDrawerOpen();
    } else if (props.defaultOpen === false) {
      handleDrawerClose();
    }
    auth
      .checkSession()
      .then((user) => {
        setLogged(true);
      })
      .catch(() => {
        setLogged(false);
        if (!window.location.pathname.endsWith("/login"))
          history.push("/auth/login");
      });
  }, []);

  const renderContent = () => {
    if (activeDashIcon) {
      return activeDashIcon.content;
    } else if (props.content) {
      return props.content;
    } else {
      return <div></div>;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            INFOROW
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={handleDrawerClose}
            className={classes.dashboardIcons}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {logged !== false ? (
            <div>
              {navButtons.map((button, index) => (
                <ListItem button key={button.name} onClick={button.action}>
                  <ListItemIcon
                    className={
                      props.active == button.name
                        ? classes.dashboardActiveIcon
                        : classes.dashboardIcons
                    }
                  >
                    {button.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={button.name}
                    className={
                      props.active == button.name
                        ? classes.dashboardActiveText
                        : classes.dashboardText
                    }
                  />
                </ListItem>
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </List>
        <Divider />
        <List>
          {props.dashboardButtons.map((button, index) => (
            <ListItem
              button
              key={button.name}
              onClick={() => setActiveDashIcon(button)}
            >
              <ListItemIcon
                className={
                  activeDashIcon && activeDashIcon.name == button.name
                    ? classes.dashboardActiveIcon
                    : classes.dashboardIcons
                }
              >
                {button.icon}
              </ListItemIcon>
              <ListItemText
                primary={button.name}
                className={
                  activeDashIcon && activeDashIcon.name == button.name
                    ? classes.dashboardActiveText
                    : classes.dashboardText
                }
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div
          className={classes.drawerHeader}
          style={{ display: props.drawerHeader == false ? "none" : "block" }}
        />
        {renderContent()}
      </main>
    </div>
  );
}
