import { Map, GoogleApiWrapper, Legend } from "google-maps-react";
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import api from "../api";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";

const mapRef = React.createRef();
const inputRef = React.createRef();
const areaInfoRef = React.createRef();

function MapContainer(props) {
  const [map, setMap] = useState(null);
  const [mapProps, setMapProps] = useState(null);
  const [file, setFile] = useState(null);
  const [finalInput, setFinalInput] = useState({
    newFileName: "",
    generatedJson: "",
  });
  const [firstFile, setFirstFile] = useState(true);
  const [credits, setCredits] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [terms, setTerms] = useState(false);
  const [finalDone, setFinalDone] = useState(false);
  const [areaInfo, setAreaInfo] = useState({
    area: 0,
    longitude: "",
    latitude: "",
    warranty: null,
    tutorial: null,
    error: null,
  });

  const legendBaseStyle = {
    backgroundColor: "whitesmoke",
    padding: "1rem",
    color: "#17b978",
    margin: "1rem",
    borderRadius: "4px",
    minWidth: "350px",
    maxWidth: "400px",
  };

  const areaInfoStyle = {
    backgroundColor: "whitesmoke",
    padding: "1rem",
    color: "#17b978",
    margin: "1rem",
    borderRadius: "4px",
    minWidth: "350px",
    maxWidth: "400px",
  };

  const final = () => {
    const inputFile = finalInput.newFileName;
    const lastJson = finalInput.generatedJson;
    axios({
      method: "post",
      url: api.url + "/irmanager/final",
      data: { inputFile, lastJson },
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setFinalDone(true);
        setFile(null);
        updateLegend(areaInfoRef, areaInfoLegend("none"));
      })
      .catch((err) => {
        //TODO: Renderizar snackbar com erro.
      });
  };

  const inputLegend = (
    <div style={legendBaseStyle}>
      <Typography variant="h5">IRManager</Typography>
      <Divider style={{ margin: "0.5rem 0rem" }} />
      <input
        type="file"
        name="inputEP2"
        id="inputEP2"
        style={{ display: "none" }}
        accept=".ep2,.zip"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
      <Typography variant="h5">Créditos: {credits.toFixed(2)}</Typography>
      <label
        className="green-label"
        for="inputEP2"
        style={{ marginTop: "0.5rem" }}
      >
        Enviar arquivo
      </label>
      <label id="file-name" style={{ marginLeft: "0.5rem", fontSize: "16px" }}>
        {file ? file.name : "Arquivo não selecionado..."}
      </label>
    </div>
  );

  const renderAdditionalInfoArea = () => {
    const { warranty, tutorial, error } = areaInfo;
    let message = null;
    if (error !== null) message = error;
    else if (tutorial !== null)
      message = "Essa área faz parte do tutorial! Você não será cobrado.";
    else if (warranty !== null)
      message =
        "Essa área já foi processada anteriormente, como ainda está dentro do prazo de 72 horas não será cobrado créditos para o reprocessamento.";
    if (message !== null) {
      return (
        <div>
          <Typography style={{ color: error !== null ? "red" : "#17b978" }}>
            {message}
          </Typography>
        </div>
      );
    } else return <div></div>;
  };

  const areaInfoLegend = (display) => {
    const { area, longitude, latitude, warranty, tutorial, error } = areaInfo;
    const areaInfoLabels = {
      color: "black",
      fontSize: "20px",
    };
    return (
      <div style={{ ...areaInfoStyle, display }}>
        <Typography variant="h5">Informações:</Typography>
        <Divider style={{ margin: "1rem 0rem" }} />
        {renderAdditionalInfoArea()}
        <div style={{ marginTop: "0.5rem" }}>
          <Typography style={areaInfoLabels}>Área total: {area} ha</Typography>
          {error === null ? (
            <Typography style={areaInfoLabels}>
              Créditos após:{" "}
              {warranty == null && tutorial == null ? credits - area : credits}
            </Typography>
          ) : (
            <div></div>
          )}
          <Typography style={areaInfoLabels}>Longitude: {longitude}</Typography>
          <Typography style={areaInfoLabels}>Latitude: {latitude}</Typography>
          <Divider style={{ margin: "0.5rem 0rem" }} />
          {error === null ? (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={terms}
                    onChange={(e) => {
                      setTerms(e.target.checked);
                    }}
                  />
                }
                label="Concordo com os termos de uso da ferramenta."
              />
              <br />
              <div style={{ marginTop: "0.5rem", textAlign: "center" }}>
                {terms ? (
                  <Button color="primary" variant="contained" onClick={final}>
                    Executar
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" disabled>
                    Executar
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  };

  const renderLegend = (legendRef, legendContent, position) => {
    if (map && mapProps) {
      const legend = legendRef.current;
      const { google } = mapProps;
      ReactDOM.render(legendContent, legend, () => {});
      map.controls[google.maps.ControlPosition[position]].push(legend);
    }
  };

  const updateLegend = (legendRef, legendContent) => {
    if (map && mapProps) {
      const legend = legendRef.current;
      ReactDOM.render(legendContent, legend, () => {});
    }
  };

  const overview = (e) => {
    if (file) {
      const options = {
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          let percent = Math.floor((loaded * 100) / total);
          console.log(percent);
        },
      };
      setUploading(true);
      const form = new FormData();
      form.append("inputEP2", file);
      axios({
        method: "post",
        url: api.url + "/irmanager/overview",
        data: form,
        headers: {
          "access-token": localStorage.getItem("token"),
        },
        onUploadProgress: (event) => {
          const { loaded, total } = event;
          let percent = Math.floor((loaded * 100) / total);
          setUploadProgress(percent);
        },
      })
        .then((response) => {
          setUploading(false);
          setUploadProgress(0);
          let error = null;
          if (
            response.data.warranty == undefined &&
            response.data.tutorial == undefined &&
            credits < response.data.area
          ) {
            error = "Você não possui créditos suficiente.";
          }
          setFinalInput({
            newFileName: response.data.newFileName,
            generatedJson: response.data.generatedJson,
          });
          setAreaInfo({
            area: response.data.area,
            longitude: response.data.longitude,
            latitude: response.data.latitude,
            warranty: response.data.warranty,
            tutorial: response.data.tutorial,
            error,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (file) {
      if (firstFile == true) {
        setFirstFile(false);
        renderLegend(areaInfoRef, areaInfoLegend("block"), "RIGHT_TOP");
      } else {
        updateLegend(areaInfoRef, areaInfoLegend("block"));
      }
    }
  }, [areaInfo]);

  useEffect(() => {
    if (inputRef) {
      renderLegend(inputRef, inputLegend, "LEFT_TOP");
    }
  }, [map, mapProps]);

  useEffect(() => {
    if (inputRef) {
      updateLegend(inputRef, inputLegend);
      overview();
    }
  }, [file]);

  useEffect(() => {
    updateLegend(inputRef, inputLegend);
  }, [credits]);

  useEffect(() => {
    updateLegend(areaInfoRef, areaInfoLegend());
  }, [terms]);

  useEffect(() => {
    axios({
      method: "get",
      url: api.url + "/reloads/company/session",
      data: {},
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        let total = 0;
        response.data.reloads.forEach((r) => {
          total += r.availableValue;
        });
        setCredits(total);
      })
      .catch((err) => console.log(err));
  }, []);

  const onMapReady = (mapProps, map) => {
    setMap(map);
    setMapProps(mapProps);
  };

  const mapDivStyle = () => {
    return {
      marginTop: "64px",
      overflowX: "hidden",
      width: props.sidebarStatus == true ? `calc(100% - 240px)` : "100%",
    };
  };

  const mapStyle = () => {
    return {
      right: "24px",
      width: props.sidebarStatus == true ? `calc(100% - 240px)` : "100%",
      height: `calc(100% - 64px)`,
      position: "relative",
    };
  };

  return (
    <div style={mapDivStyle()} id="mapDiv">
      <Snackbar open={true}>
        <div
          style={{
            width: "25rem",
            backgroundColor: "whitesmoke",
            padding: "0.5rem",
            borderRadius: "4px",
            display: uploading ? "block" : "none",
          }}
        >
          <LinearProgress variant="determinate" value={uploadProgress} />
        </div>
      </Snackbar>
      <Snackbar
        open={finalDone}
        autoHideDuration={20000}
        onClose={() => setFinalDone(false)}
      >
        <MuiAlert variant="filled" severity="success">
          Processo colocado na fila com sucesso! Vá para a página de downloads
          para visualizá-lo.
        </MuiAlert>
      </Snackbar>
      <Map
        id="map"
        style={mapStyle()}
        google={props.google}
        zoom={12}
        initialCenter={{ lat: -22.725, lng: -47.6476 }}
        mapType={"satellite"}
        mapRef={mapRef}
        disableDefaultUI={true}
        onReady={onMapReady}
      ></Map>
      <div ref={inputRef}></div>
      <div ref={areaInfoRef}></div>
    </div>
  );
}

export default GoogleApiWrapper((props) => ({
  apiKey: "AIzaSyAs9r64C1yfZ3a_7MexGnI4j2o3_lGHoO8",
}))(MapContainer);
