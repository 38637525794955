export const parseISODate = (isoDate) => {
  let [date, hour] = isoDate.split("T");
  hour = hour.split(".")[0];
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}  ${hour}`;
};

export const yearFromNow = () => {
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  const day = oneYearFromNow.getDay().toString().padStart(2, "0");
  const month = oneYearFromNow.getMonth().toString().padStart(2, "0");
  return `${oneYearFromNow.getFullYear()}-${month}-${day}`;
};
